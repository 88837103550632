export default () => {
  const _importScripts = self['importScripts'] ? self['importScripts'] : () => {}; // eslint-disable-line no-restricted-globals
	self.addEventListener('message', messageEvent => { // eslint-disable-line no-restricted-globals
    if (!messageEvent) return;

    let daikon = null, pako = null, fflate = null, path = null;
    if (messageEvent.data.url) {
      postMessage({daikon: daikon ? 'loaded' : 'notloaded'});
    }
    if (messageEvent.data.items) {
      if (!daikon) {
        _importScripts('https://cdn.jsdelivr.net/npm/daikon@1.2.42/release/current/daikon-min.js');
        daikon = self['daikon'];  // eslint-disable-line no-restricted-globals
      }
      if (!fflate) {
        _importScripts('https://unpkg.com/fflate@0.8.0');
        _importScripts('https://cdn.jsdelivr.net/npm/fflate@0.8.0/umd/index.js');
        fflate = self['fflate'];  // eslint-disable-line no-restricted-globals
      }      

      let setCount = 0;
      const files = {};
      const zipTransfers = {};
      const sets = {};
      const minMax = {};
      const invalids = [];
      const invalidsObject = {};
      const totalFiles = messageEvent.data.items.length;
      let toProcess = totalFiles;

      function Clamp(val, min, max) {
        if (val < min) return min;
        if (val > max) return max;
        return val;
      }

      const getFileMap = (files) => {
        var fileMap = {};
        files.forEach(file => {
          var newName = getPathWithoutRootFolder(file.name);
          fileMap[newName] = new Uint8Array(file.data);
        })
        return fileMap;
      }

      const getPathWithoutRootFolder = (path) => {
        var sliced = path.slice('/');
        if (sliced.length > 1) {
          var regex = /^[^\/]+\//
          return path.replace(regex, '');
        }
      }

      const getMtlFromObj = (data, objPath) => {
        const decoder = new TextDecoder('utf-8');
        var objFileText = decoder.decode(data);
        const _material_library_pattern = /^mtllib /;
        var mtlFiles = [];

        if ( objFileText.indexOf( '\r\n' ) !== - 1 ) {
            // This is faster than String.split with regex that splits on both
            objFileText = objFileText.replace( /\r\n/g, '\n' );
        }

        if ( objFileText.indexOf( '\\\n' ) !== - 1 ) {
            // join lines separated by a line continuation character (\)
            objFileText = objFileText.replace( /\\\n/g, '' );
        }

        const lines = objFileText.split( '\n' );
        let line = '', lineFirstChar = '';
        let lineLength = 0;

        // Faster to just trim left side of the line. Use if available.
        const trimLeft = ( typeof ''.trimLeft === 'function' );

        for ( let i = 0, l = lines.length; i < l; i ++ ) {

          line = lines[ i ];
          line = trimLeft ? line.trimLeft() : line.trim();
          lineLength = line.length;

          if ( lineLength === 0 ) continue;

          lineFirstChar = line.charAt( 0 );

          // @todo invoke passed in handler if any
          if ( lineFirstChar === '#' ) continue; //comment -> skip

          if ( _material_library_pattern.test( line ) ) {
              // MTL FILE
              mtlFiles.push(line.substring(7).trim());
          }
          else {
              //don't care, not MTL file
          }
        }

        var relativeMtlPaths = mtlFiles.map(mtlPath => {
          return resolveRelativePath(objPath, mtlPath);
        });
        return relativeMtlPaths;
      }
  
      const getMapsFromMtl = (data, mtlPath) => {
        const decoder = new TextDecoder('utf-8');
        var mtlFileText = decoder.decode(data);
        var maps = [];
        mtlFileText = mtlFileText.replace(/\\\\/g, '/');
        const lines = mtlFileText.split( '\n' );

        for ( let i = 0; i < lines.length; i ++ ) {

            let line = lines[ i ];
            line = line.trim();

            if ( line.length === 0 || line.charAt( 0 ) === '#' ) {
                // Blank line or comment ignore
                continue;
            }

            const pos = line.indexOf( ' ' );

            let key = ( pos >= 0 ) ? line.substring( 0, pos ) : line;
            key = key.toLowerCase();

            let value = ( pos >= 0 ) ? line.substring( pos + 1 ) : '';
            value = value.trim();

              if (key.startsWith('map')) {
                var path = getMapRawPath(value);
                if (path.startsWith('/')) path = path.substring(1);
                maps.push(path);
              }
              else {
                  //don't care, not map
              }
        }
        var mapsRelative = getRelativeMapsPaths(maps, mtlPath);
        return mapsRelative;
      }

      const getDirectoryFromPath = (fullPath) => {
        var splitPath = fullPath.split('/')
        if(splitPath.length === 1){
          return null
        }else{
          splitPath.pop()
          return splitPath.join('/')
        }
      }

      const getRelativeMapsPaths = (maps, mtlPath) => {

        var result = maps.map(mapPath => {
            return resolveRelativePath(mtlPath, mapPath);
        })
        return result;
      }

      const resolveRelativePath = (filePath, relativePath) => {

        var partsParent = filePath.split('/');
        partsParent.pop();

        var parts = relativePath.split('/');

        for (var i = 0; i < parts.length; i++) {
          var part = parts[i];
          
          if (part == '..') {
            if (partsParent.length == 0) {
              return false;
            }
            partsParent.pop();
          }
          else if (part == '.') {
            continue;
          }
          else {
            partsParent.push(part);
          }
        }
        return partsParent.join('/');
      }
      const getMapRawPath = (value) => {
        const regexArr = [/^-[a-z]+ [^ ]+ /, /^-[a-z]+ [^ ]+ [^ ]+ /, /^-[a-z]+ [^ ]+ [^ ]+ [^ ]+ /]

        if (value.startsWith('-o ') || value.startsWith('-s ') || value.startsWith('-t ')) { //3 values
            return getMapRawPath(value.replace(regexArr[2], ''));
        }
        else if (value.startsWith('-mm ')) { //2 values
            return getMapRawPath(value.replace(regexArr[1], ''));
        }
        else if (value.startsWith('-blendu ') || value.startsWith('-blendv ') || value.startsWith('-cc ') 
        || value.startsWith('-clamp ') || value.startsWith('-texres ') || value.startsWith('-imfchan ')) { //1 val
            return getMapRawPath( value.replace(regexArr[0], ''));
        }
        else {
            return value;
        }
      }
      
      const getAllFilesForObj = (sets, objSet) => {
        var resultFiles = [];
        var mapPaths = [];
        for (const key in sets) {
          if (key.toLowerCase().startsWith('mtl')) {
            var set = sets[key];
            objSet.mtlPaths.forEach(mtlPath => {
              if (set.name == mtlPath){
                resultFiles = resultFiles.concat(set.files);
                mapPaths = mapPaths.concat(set.mapsPaths);
              }
            });
          }
        }
        if (!resultFiles.length || !mapPaths.length) return null;
        var mapPathsCtr = 0;
        for (const key in sets) {
          if (key.toLowerCase().startsWith('image')) {
            var set = sets[key];
            mapPaths.forEach(mapPath => {
              if (set.name == mapPath){
                resultFiles = resultFiles.concat(set.files);
                mapPathsCtr++;
              }
            });
          }
        }
        var delta = mapPaths.length - mapPathsCtr;
        if (delta > 0) {
          console.warn(`${delta} map(s) not found`); 
        }
        else if (delta < 0) {
          console.warn(`${-delta} multiple map(s)`); 
        }
        return resultFiles;
      }

      const getAllFilesForFbx = (sets, fbxSet) => {
        var resultFiles = [];
        if(fbxSet.dirName){
          for (const key in sets) {
            if (key.toLowerCase().startsWith('image')) {
              var set = sets[key];
              var setDirPathSplit = set.name.split('/').slice(0,fbxSet.dirName.split('/').length).join('/')

              if (setDirPathSplit === fbxSet.dirName){
                resultFiles = resultFiles.concat(set.files);
              }
            }
          }
        }
        return resultFiles;
      }

      const getGltfPaths = (data) => {
        try {
          const decoder = new TextDecoder("utf-8");
          var temp = decoder.decode(data);
          var gltfObject = JSON.parse(temp);
          var connectedFileNames = gltfObject.buffers.map(element => element.uri)
          connectedFileNames = connectedFileNames.concat(gltfObject.images.map(element => element.uri))
          return connectedFileNames;
        } catch (error) {
          return [];
        };
      }

      const getAllFilesForGltf = (sets, gltfSet) => {
        const lastDotIndex = gltfSet.name.lastIndexOf('.');
        const extenstion = gltfSet.name.substring(lastDotIndex);

        if (extenstion !== ".gltf") {
          return [];
        };

        var resultFiles = [];

        for (const connectedFileName of gltfSet.gltfPaths) {
          if (!connectedFileName) {
            continue;
          };

          const lastDotIndexConnectedFile = connectedFileName.lastIndexOf('.');
          const connectedFileExtenstion = connectedFileName.substring(lastDotIndexConnectedFile);

          if (extensionsMap.get(connectedFileExtenstion)) {
            for (const key in sets) {
              var set = sets[key];
              if (set.name.includes(connectedFileName)){
                resultFiles = resultFiles.concat(set.files);
                break;
              };
            };
          } else {
            for (const key in invalidsObject) {
              var invalid = invalidsObject[key];
              if (invalid.name.includes(connectedFileName)){
                resultFiles = resultFiles.concat({name: invalid.name, data: invalid.data});
                break;
              };
            };
          };
        };
        return resultFiles;
      }

      console.time("readfiles");

      const extensionsMap = new Map();
      extensionsMap.set('.stl', 'stl');
      extensionsMap.set('.jpg', 'image');
      extensionsMap.set('.jpeg', 'image');
      extensionsMap.set('.png', 'image');
      extensionsMap.set('.rxr', 'recordxr');
      extensionsMap.set('.pdf', 'document');
      extensionsMap.set('.obj', 'obj');
      extensionsMap.set('.mtl', 'mtl');
      extensionsMap.set('.ogv', 'video');
      extensionsMap.set('.vp8', 'video');
      extensionsMap.set('.webm', 'video');
      extensionsMap.set('.mov', 'video');
      extensionsMap.set('.dv', 'video');
      extensionsMap.set('.mp4', 'video');
      extensionsMap.set('.m4v', 'video');
      extensionsMap.set('.mpg', 'video');
      extensionsMap.set('.mpeg', 'video');
      extensionsMap.set('.avi', 'video');
      extensionsMap.set('.asf', 'video');
      extensionsMap.set('.wmf', 'video');
      extensionsMap.set('.fbx', 'fbx');
      extensionsMap.set('.gltf', 'gltf');
      extensionsMap.set('.glb', 'gltf');

      messageEvent.data.items.forEach((file, ix) => {
        const reader = new FileReader();
        const fullPath = messageEvent.data.fullPaths[ix];
        reader.onload = (progressEvent) => {
          toProcess--;

          const arrayBuffer = progressEvent.target.result;
          const data = new DataView(arrayBuffer);
          const image = daikon.Series.parseImage(data);

          if (image === null || !image.hasPixelData()) {
            const fileName= file.name.toLowerCase();
            let fileType;
            let lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex > 0) {
              fileType = extensionsMap.get(fileName.substring(lastDotIndex));
            }else {
              fileType = 'other';
            }
            switch(fileType) {
              case 'stl':
                sets["stl-" + Math.random()] = {
                  obj: true,
                  type: "stl",
                  meshData: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'image':
                sets["image-" + Math.random()] = {
                  obj: false,
                  type: "image",
                  imageBuffer: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'recordxr':
                sets["recordxr-" + Math.random()] = {
                  obj: false,
                  type: "recordxr",
                  recordXRBuffer: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'document':
                sets["document-" + Math.random()] = {
                  obj: false,
                  type: "document",
                  documentData: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'obj':
                var mtlPaths = getMtlFromObj(data, fullPath);
                sets["obj-" + Math.random()] = {
                  obj: true,
                  type: "obj",
                  meshData: arrayBuffer,
                  mtlPaths: mtlPaths,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'fbx':
                var dirName = getDirectoryFromPath(fullPath)
                sets["fbx-" + Math.random()] = {
                  obj: false,
                  type: "fbx",
                  dirName: dirName,
                  meshData: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'gltf':
                var gltfPaths = getGltfPaths(arrayBuffer);
                sets["gltf-" + Math.random()] = {
                  obj: false,
                  type: "gltf",
                  gltfPaths: gltfPaths,
                  meshData: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'mtl':
                var mapsPaths = getMapsFromMtl(data, fullPath);
                sets["mtl-" + Math.random()] = {
                  obj: false,
                  type: "mtl",
                  mapsPaths: mapsPaths,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              case 'video':
                sets["video-" + Math.random()] = {
                  obj: false,
                  type: "video",
                  videoBuffer: arrayBuffer,
                  name: fullPath,
                  files: [{name: fullPath, data: arrayBuffer}]
                };
                setCount++;
                postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
                break
              default:
                invalids.push(file);
                invalidsObject[fullPath] = {name: fullPath, data: arrayBuffer}
            }
            

          }
          else {
            // file is DICOM

            let id = image.getSeriesId() + image.getRows() + image.getCols() + image.getImageType().join(";");

            let numberOfFrames = image.getTag(0x0028, 0x0008);
            if (numberOfFrames && numberOfFrames.value && numberOfFrames.value.length && numberOfFrames.value[0] > 1) {
              id = image.getTag(0x0008, 0x0018).value[0];
            }

            if (!(id in sets)) {
              sets[id] = new daikon.Series();
              files[id] = [];
              setCount++;
            }
                
            // gather pixel data &  min/max
            image.interpretedData = image.getInterpretedData(false, true);
  
            files[id].push(file);
            sets[id].addImage(image);

            postMessage({stat:{leftFiles: toProcess, totalFiles: totalFiles}});
          }
          
          if (toProcess === 0) {
            console.timeEnd("readfiles");
            const setIds = Object.keys(sets);

            console.log(setIds);

            if (!setIds.length) {
              postMessage({length: invalids.length, invalids: invalids});
              return;
            }

            let setIdsToTransfer = setIds.length;

            postMessage({stat: {leftSets: setIdsToTransfer}});
            
            const finalizeSet = () => {
              setIdsToTransfer--;
              postMessage({stat: {leftSets: setIdsToTransfer}});

              if (setIdsToTransfer === 0) {
                postMessage({length: invalids.length, invalids: invalids});
              }
            }

            setIds.forEach(id => {
              const currentSet = sets[id];

              if ("files" in currentSet && currentSet.obj === true && currentSet.type != 'obj') {
                // stl files 
                const fileMap = getFileMap(currentSet.files);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "models",
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  fileData: zipped,
                  meshData: currentSet.meshData,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer, currentSet.meshData]);

                finalizeSet();
                return;
              }
              else if (currentSet.type == 'image') {
                const fileMap = getFileMap(currentSet.files);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "image"
                };
                
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  fileData: zipped,
                  imageBuffer: currentSet.imageBuffer,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'recordxr') {
                const fileMap = getFileMap(currentSet.files);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "rxr",
                    Data:  "recordxr"
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  fileData: currentSet.recordXRBuffer,
                  recordXRBuffer: currentSet.recordXRBuffer,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'video') {
                const fileMap = getFileMap(currentSet.files);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "video"
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  fileData: zipped,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'document') {
                const fileMap = getFileMap(currentSet.files);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "document"
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  pdf: true,
                  fileData: zipped,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'obj') {
                var allFiles = getAllFilesForObj(sets, currentSet);
                if (allFiles) {
                  allFiles = allFiles.concat(currentSet.files);
                }
                else {
                  allFiles = currentSet.files;
                }
                const fileMap = getFileMap(allFiles);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "models",
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  obj: true,
                  fileData: zipped,
                  meshData: currentSet.meshData,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer, currentSet.meshData]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'fbx') {
                var allFiles = getAllFilesForFbx(sets, currentSet);
                if (allFiles.length !== 0) {
                  allFiles = allFiles.concat(currentSet.files);
                }
                else {
                  allFiles = currentSet.files;
                }
                const fileMap = getFileMap(allFiles);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "models",
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  obj: true,
                  fbx: true,
                  fileData: zipped,
                  meshData: currentSet.meshData,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer, currentSet.meshData]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'gltf') {
                var allFiles = getAllFilesForGltf(sets ,currentSet);
                if (allFiles.length !== 0) {
                  allFiles = allFiles.concat(currentSet.files);
                }
                else {
                  allFiles = currentSet.files;
                }
                const fileMap = getFileMap(allFiles);
                const zipped = fflate.zipSync(fileMap, { level: 1 } );
                var newPath = getPathWithoutRootFolder(currentSet.name);
                const metaData = {
                    Description: newPath,
                    Path:  newPath,
                    Format:  "zip",
                    Data:  "models",
                };
                postMessage({
                  set: id,
                  length: 1,
                  gzip: false,
                  gltf: true,
                  fileData: zipped,
                  meshData: currentSet.meshData,
                  imageData: false,
                  rawImageData: null,
                  metaData: metaData
                }, [zipped.buffer, currentSet.meshData]);
                finalizeSet();
                return;
              }
              else if (currentSet.type == 'mtl') {
                finalizeSet();
                return;
              }
              
              const series = sets[id];

              console.time("seriesbuild");
              
              series.buildSeries();

              if (series.isMultiFrame && series.images[0].getFrameTime() === null) {
                series.isMultiFrameVolume = true;
                series.isMultiFrameTimeseries = false;
              }

              if (series.isMultiFrameVolume) {

                const getTag = (item, group, element) => {
                  const tagId = daikon.Tag.createId(group, element);
                  if (item.value[tagId]) {
                      return item.value[tagId];
                  }
                  return null;              
                };

                const processTagIds = (tags) => {
                  var newTags = {}
                  for (var t = 0; t < tags.length; t++) {
                    var tag = tags[t];
                    newTags[tag.id] = tag;
                  }
                  return newTags;
                }

                const copyTags = (sourceImage, targetImage) => {
                  const length = Object.keys(sourceImage.tags).length;

                  for (var i = 0; i < length; i++) {
                    const tag = sourceImage.tags[Object.keys(sourceImage.tags)[i]];
                    targetImage.putTag(tag);
                  }
                }

                var newSeries = new daikon.Series();
                var image = series.images[0];

                for (var i = 0; i < series.numberOfFramesInFile; i++) {
                  var slice = image.getInterpretedData(false, true, i);
                  const sliceImg = new daikon.Image();
                  
                  sliceImg.index = i;
                  copyTags(image, sliceImg);

                  sliceImg.interpretedData = slice; 

                  // Per-Frame Functional Groups Sequence
                  var sharedItem = image.getTag(0x5200, 0x9230).value[i];

                  // // Shared Functional Groups Sequence
                  // var sharedItem = image.getTag(0x5200, 0x9229).value[0];                  
                  sharedItem.value = processTagIds(sharedItem.value);

                  var pixelMeasuresSequence = getTag(sharedItem, 0x0028, 0x9110);
                  if (pixelMeasuresSequence && pixelMeasuresSequence.value.length > 0) {
                    var pixelMeasuresItem = pixelMeasuresSequence.value[0];

                    if (pixelMeasuresItem.value.length > 0) {
                      pixelMeasuresItem.value = processTagIds(pixelMeasuresItem.value);

                      var sliceThickness = getTag(pixelMeasuresItem, 0x0018, 0x0050);
                      if (sliceThickness) sliceImg.putTag(sliceThickness);
  
                      var pixelSpacing = getTag(pixelMeasuresItem, 0x0028, 0x0030);
                      if (pixelSpacing) sliceImg.putTag(pixelSpacing);
                    }
                  }

                  var planeOrientationSequence = getTag(sharedItem, 0x0020, 0x9116);
                  if (planeOrientationSequence && planeOrientationSequence.value.length > 0) {
                    var planeOrientation = planeOrientationSequence.value[0].value[0]; // "0x0020,0x0037"
                    sliceImg.putTag(planeOrientation);
                  }

                  var planePositionSequence = getTag(sharedItem, 0x0020, 0x9113);
                  if (planePositionSequence && planePositionSequence.value.length > 0) {
                    var planePosition = planePositionSequence.value[0].value[0]; // "0x0020,0x0032"
                    sliceImg.putTag(planePosition);
                  }

                  newSeries.addImage(sliceImg);
                }

                var sliceDir = newSeries.images[0].getAcquiredSliceDirection();
                newSeries.images = daikon.Series.orderDicoms(newSeries.images, 1, sliceDir);
                series.images = newSeries.images;
              }


              if (series.images[0].getImagePosition()) {
                // invert ordering
                // this is due to daikon ordering images by imageposition
                // in the reverse order to the mhd dicom viewer
                // this makes also an assumption, that volumetric data contains this tag, whereas 2d data (US/XA,/) does not
                // for 2d case, it should not reverse slices (as it would play multiframe images in reverse)
                series.images.reverse();
              }

              console.timeEnd("seriesbuild");

              console.time("seriescalc");

              const firstImage = series.images[0];
              const lastImage = series.images[series.images.length - 1];
            
              try {
                const numberOfFrames = series.isMultiFrameVolume ? 1 : firstImage.getNumberOfFrames();
                const numberOfSlices = series.images.length || 1;

                // here concatenate interpretated data & find out minmax of complete volume
                var elementsPerSlice = firstImage.getCols() * firstImage.getRows() * numberOfFrames;
                
                // scale values
                var modalityLutType = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028, 0x3004), 0);
                var rescaleType = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028, 0x1054), 0);

                var scaleType = firstImage.getDataScaleIntercept() === null ? "US" : // unspecified
                  (modalityLutType != null ? modalityLutType : // CR image or such
                    (rescaleType  != null ? rescaleType : // Other images
                        (firstImage.getModality() == "CT" ? "HU" : "US")));

                var slope = firstImage.getDataScaleSlope() || 1;
                var intercept = firstImage.getDataScaleIntercept() || 0;

                let scaleValue = (minX, maxX, minY, maxY, value) => {
                  return (value - minX) * ((maxY - minY) / (maxX - minX)) + minY;
                };

                let lutCorrect = (value) => {
                  return ((value) * slope) + intercept;
                }

                // scale grayscale values
                var photometric = firstImage.getPhotometricInterpretation() || "";
                var isGrayscale = photometric.indexOf("MONOCHROME") !== -1;
                if (isGrayscale) {
                  var imageData = new Uint16Array(firstImage.getCols() * firstImage.getRows() * (numberOfFrames * numberOfSlices));

                  var reconstructionDiameterMask = new Uint16Array(firstImage.getCols() * firstImage.getRows() * (numberOfFrames * numberOfSlices));
                  var reconstructionDiameter = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0018,0x1100), 0);
                  
                  if (reconstructionDiameter !== null){
                    const width = firstImage.getCols();
                    const height = firstImage.getRows();
                    const pixelSpacingX = firstImage.getPixelSpacing()[0];
                    const pixelSpacingY = firstImage.getPixelSpacing()[1];

                    const centerX = ((width -1) * pixelSpacingX) / 2.0;
                    const centerY = ((height -1) * pixelSpacingY) / 2.0;
                    
                    const r = reconstructionDiameter / 2.0;
                    const r2 = Math.pow(r, 2);

                    for (var z = 0; z < series.images.length; z++) {
                      let x = 0;
                      let y = 0;
                      for (var pIx = 0; pIx < elementsPerSlice; pIx++) {

                        const isInside = Math.pow((x * pixelSpacingX - centerX), 2) + Math.pow((y * pixelSpacingY - centerY), 2) <= r2;
                        reconstructionDiameterMask[pIx + z * elementsPerSlice] = isInside ? 1 : 0;

                        x++;
                        if (x === width) {
                          x = 0;
                          y++;
                        }
                      }
                    }
                  }

                  var max16bit = 65535;
                
                  var invert = firstImage.getPhotometricInterpretation() == "MONOCHROME1";

                  var lowerIgnoreBound = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028,0x0120), 0);
                  var upperIgnoreBound = daikon.Image.getSingleValueSafely(firstImage.getTag(0x0028,0x0121), 0);

                  // lut correct to compare with interpretedData
                  lowerIgnoreBound = lowerIgnoreBound !== null ? lutCorrect(lowerIgnoreBound) : null;
                  upperIgnoreBound = upperIgnoreBound !== null ? lutCorrect(upperIgnoreBound) : null;

                  // define value range
                  let max = 0, min = 100000;

                  // fixed for Hounsfield Units
                  if (scaleType == "HU")
                  {
                      min = -1024;
                      max = 3071;
                  }
                  else {
                    if (lowerIgnoreBound !== null) {
                      console.time("minmaxcalc-withbounds");
                      // find min max with an alternative, slower variant in case pixel padding is in place
                      for (var z = 0; z < series.images.length; z++) {
                        for (var pIx = 0; pIx < elementsPerSlice; pIx++) {
                          var datapoint = series.images[z].interpretedData.data[pIx];
                          var ignoreValue = (upperIgnoreBound !== null && datapoint >= lowerIgnoreBound && datapoint <= upperIgnoreBound) ||
                            datapoint == lowerIgnoreBound;

                          if (reconstructionDiameter !== null){
                            if (reconstructionDiameterMask[pIx + z * elementsPerSlice] === 0) {
                              ignoreValue = true;
                            }
                          }

                          if (!ignoreValue) {
                            if (datapoint < min) min = datapoint;
                            if (datapoint > max) max = datapoint;
                          }
                        }
                      }
                      console.timeEnd("minmaxcalc-withbounds");
                    }
                    else {
                      console.time("minmaxcalc");
                      for (var z = 0; z < series.images.length; z++) {
                        min = Math.min(min, series.images[z].interpretedData.min);
                        max = Math.max(max, series.images[z].interpretedData.max);
                      }
                      console.timeEnd("minmaxcalc");
                    }
                  }
                  
                  var scaleLower = min;
                  var scaleUpper = max;

                  for (var z = 0; z < series.images.length; z++) {
                    var lutPixels = series.images[z].interpretedData.data;
  
                    // scale
                    for (var i = 0; i < elementsPerSlice; i++) {
                      var lutPx = lutPixels[i];
                      
                      if (lowerIgnoreBound !== null) {
                        var ignoreValue = (upperIgnoreBound !== null && lutPx >= lowerIgnoreBound && lutPx <= upperIgnoreBound) ||
                          (lutPx == lowerIgnoreBound);

                        if (ignoreValue) { 
                          lutPx = scaleLower;
                        }
                      }                      
                      
                      if (reconstructionDiameter !== null){
                        if (reconstructionDiameterMask[i + z * elementsPerSlice] === 0) {
                          lutPx = scaleLower;
                        }
                      }

                      var scaledVal = scaleValue(scaleLower, scaleUpper, 0, max16bit, lutPx);
                      
                      if (invert) {
                        scaledVal = max16bit - scaledVal;
                      }
  
                      imageData[i + z * elementsPerSlice] = scaledVal;
                    }
                  }
                }
                else {
                  // RGB/YBR data
                  var slices = series.images.length || 1;
                  var imageData = new Uint8Array(firstImage.getCols() * firstImage.getRows() * (numberOfFrames || 1) * slices * 3);

                  var isYbr = photometric.indexOf("YBR") !== -1;

                  for (var z = 0; z < slices; z++) {  
                    var rawData = series.images[z].interpretedData.data;
                    imageData.set(rawData, firstImage.getCols() * (numberOfFrames || 1) * firstImage.getRows() * z * 3 );
                  }
                }

                console.time("metadata");

                const bodyPartTag = lastImage.getTag(0x0018, 0x0015);

                const frameRate = firstImage.getTag(0x0008,0x2144);
                const frameTimes = firstImage.getTag(0x0018, 0x1065);

                const processMetadata = {
                  scale_type: scaleType,
                  photometric: isGrayscale ? firstImage.getPhotometricInterpretation() : 'RGB',
                  scale_lower: scaleLower,
                  scale_upper: scaleUpper,
                  invert: invert,
                  intercept: intercept,
                  slope: slope
                };

                if (processMetadata.photometric === 'YBR_FULL_422') {
                  processMetadata.photometric = 'YBR_FULL'; // daikon already processes 422 part
                }

                if (firstImage.getWindowWidth()) {
                  processMetadata.window_width = firstImage.getWindowWidth();
                  processMetadata.window_center = firstImage.getWindowCenter();
                  let minFilter = (processMetadata.window_center - 0.5 * processMetadata.window_width);
                  let maxFilter = (processMetadata.window_center + 0.5 * processMetadata.window_width);
                  processMetadata.min_filter = scaleValue(scaleLower, scaleUpper, 0, 1, minFilter);
                  processMetadata.max_filter = scaleValue(scaleLower, scaleUpper, 0, 1, maxFilter);
                }

                var imgDirection = firstImage.getImageDirections();
                if (imgDirection) {
                  processMetadata.patientorientationx = `${imgDirection[0]},${imgDirection[1]},${imgDirection[2]}`;
                  processMetadata.patientorientationy = `${imgDirection[3]},${imgDirection[4]},${imgDirection[5]}`;
                }
                var posFirst = firstImage.getImagePosition();
                var posLast = lastImage.getImagePosition();
                if (posFirst && posLast) {
                  processMetadata.patientposfirst = `${posFirst[0]},${posFirst[1]},${posFirst[2]}`;
                  processMetadata.patientposlast = `${posLast[0]},${posLast[1]},${posLast[2]}`;
                }

                const metaData = {
                  ImagePositionPatientFirst: lastImage.getImagePosition() || [-239.0224609375,-413.5224609375,1121.8],
                  ImagePositionPatientLast: firstImage.getImagePosition() || [-239.0224609375,-413.5224609375,-379],
                  SliceThickness: firstImage.getSliceThickness(),
                  ImageOrientation: firstImage.getImageDirections() || [1,0,0,0,1,0],
                  PixelSpacing: firstImage.getPixelSpacing() || [0.955078125,0.955078125],
                  PhotometricInterpretation: firstImage.getPhotometricInterpretation(),
                  BitsAllocated: firstImage.getBitsAllocated(),
                  Modality: firstImage.getModality(),
                  BodyPart: bodyPartTag && bodyPartTag.value && bodyPartTag.value.length ? bodyPartTag.value[0] : "",
                  orientation: firstImage.getOrientation() || "XYZ--+",
                  width: firstImage.getCols(),
                  height: firstImage.getRows(),
                  depth: series.images.length,
                  min: scaleLower,
                  max: scaleUpper,
                  id: id,
                  processMetadata: JSON.stringify(processMetadata),
                  numberOfFrames: numberOfFrames,
                  frameRate: frameRate && frameRate.value && frameRate.value.length ? frameRate.value[0] : null,
                  frameTimes: frameTimes && frameTimes.value && frameTimes.value.length ? frameTimes.value : null
                };

                metaData.PhysicalSize = getPhysicalSize(metaData);
                metaData.ScanDirection = getScanDirection(metaData);

                const newId = Math.floor(Math.random() * 100000 + 10000);

                console.log("Processing", newId, files[id]);

                // mirrorAxises(dataview, metaData);

                console.timeEnd("metadata");
                /*
                let times = [], imageDataCompressed;
                for (let l = 0; l <= 9; l++) {
                  const start = performance.now();
                  imageDataCompressed = fflate.gzipSync(new Uint8Array(imageData.buffer), { level: l } ).buffer;
                  let measure = {level: l, time: performance.now() - start, ratio: (imageData.buffer.byteLength / imageDataCompressed.byteLength)};
                  console.log(measure);
                  times.push(measure);
                }
                console.table(times);
                console.time("compress");
                const imageDataCompressed = fflate.gzipSync(new Uint8Array(imageData.buffer), { level: 1 } ).buffer;
                console.timeEnd("compress");
                */

                console.log(metaData);
                /*
                const imageData16 = new Int16Array(imageData);
                const imageData8 = new ArrayBuffer(imageData.byteLength / 2);

                for (var i = 0; i < imageData16.lengh; i++) {
                  imageData8[i] = Math.floor(imageData16[i] / 4096 * 256);
                }
                */
                console.time("ArrayBuffer transfer");
                postMessage({
                  set: newId, length: series.images.length, gzip: false, imageData: false, rawImageData: imageData, metaData: metaData
                }, [imageData.buffer]);
                console.timeEnd("ArrayBuffer transfer");

                finalizeSet();

                console.timeEnd("seriescalc");
              }
              catch (e) {
                console.error(e);
                finalizeSet();
              }
            });
          }
        };
  
        reader.readAsArrayBuffer(file);
      });

      postMessage({received: messageEvent.data.items.length});
    }
  })

  function getScanDirection(set) { 
    let disZ = 0;
    let dis = {
        x: Math.abs(set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0]),
        y: Math.abs(set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1]),
        z: Math.abs(set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2])
    };
    let scanDirection;

    let maxd = Math.max(dis.x, Math.max(dis.y, dis.z));
    if (dis.x > 0 && dis.y > 0 && dis.z > 0) {
        if (dis.x == maxd) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else if (dis.y == maxd) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.y > 0) {
        if (dis.y > dis.x) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
    }
    else if (dis.y > 0 && dis.z > 0) {
        if (dis.y > dis.z) {
            disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
            if (disZ > 0)	scanDirection = [0, 1, 0];
            else			scanDirection = [0, -1, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.x > 0 && dis.z > 0) {
        if (dis.x > dis.z) {
            disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
            if (disZ > 0)	scanDirection = [1, 0, 0];
            else			scanDirection = [-1, 0, 0];
        }
        else {
            disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
            if (disZ > 0)	scanDirection = [0, 0, 1];
            else			scanDirection = [0, 0, -1];
        }
    }
    else if (dis.z > 0) {
        disZ = set.ImagePositionPatientLast[2] - set.ImagePositionPatientFirst[2];
        if (disZ > 0)	scanDirection = [0, 0, 1];
        else			scanDirection = [0, 0, -1];
    }
    else if (dis.y > 0) {
        disZ = set.ImagePositionPatientLast[1] - set.ImagePositionPatientFirst[1];
        if (disZ > 0)	scanDirection = [0, 1, 0];
        else			scanDirection = [0, -1, 0];
    }
    else {
        disZ = set.ImagePositionPatientLast[0] - set.ImagePositionPatientFirst[0];
        if (disZ > 0)	scanDirection = [1, 0, 0];
        else			scanDirection = [-1, 0, 0];
    }

    return scanDirection;
  }

  function getPhysicalSize(metadata) {
    var volScale = [metadata.PixelSpacing[0] * metadata.width / 1000, metadata.PixelSpacing[1] * metadata.height / 1000, 1];
    volScale[2] = Math.sqrt(
      Math.pow(metadata.ImagePositionPatientLast[0] - metadata.ImagePositionPatientFirst[0], 2) +
      Math.pow(metadata.ImagePositionPatientLast[1] - metadata.ImagePositionPatientFirst[1], 2) +
      Math.pow(metadata.ImagePositionPatientLast[2] - metadata.ImagePositionPatientFirst[2], 2)
    ) / 1000;
  
    if (volScale[2] === 0) {
      volScale[2] = volScale[0] / metadata.width;
    }

    return volScale;
  }

  function mirrorAxises(volume, metadata) {
    const components = (metadata.PhotometricInterpretation == "RGB") ? 3 : 1;
    const totalVolumeLength = metadata.width * metadata.height * metadata.depth * components;

    if (components === 1) {
      if (metadata.orientation[3] == '+') {
        console.log("Mirroring X");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = i % metadata.width;
          if (flipRelIndexA < metadata.width / 2) {
            flipIndexB = (i - flipRelIndexA) + (metadata.width - flipRelIndexA - 1);
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[4] == '+') {
        console.log("Mirroring Y");
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / metadata.width);
          if (flipRelIndexA < metadata.height / 2) {
            flipIndexB = (i - flipRelIndexA * metadata.width) + (metadata.height - flipRelIndexA - 1) * metadata.width;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
      if (metadata.orientation[5] == '+') {
        console.log("Mirroring Z");
        let plane = metadata.width * metadata.height;
        let flipRelIndexA, flipIndexB, huB;
        for (let i = 0; i < totalVolumeLength; i++) {
          flipRelIndexA = Math.floor(i / plane);
          if (flipRelIndexA < metadata.depth / 2) {
            flipIndexB = (i - flipRelIndexA * plane) + (metadata.depth - flipRelIndexA - 1) * plane;
            huB = volume[flipIndexB];
            volume[flipIndexB] = volume[i];
            volume[i] = huB;
          }
        }
      }
    }
  }
}