import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppDashboard from './AppDashboard';
import AppMobileUpload from './AppMobileUpload';
import DeviceEmulator from './DeviceEmulator';
import * as serviceWorker from './serviceWorker';
import queryString from 'query-string';

const deviceLinkSocket = window.io(
  `${(process.env.NODE_ENV === 'development' ?
    process.env.REACT_APP_DEV_DEVICE_LINK_ENDPOINT :
    process.env.REACT_APP_DEVICE_LINK_ENDPOINT
  )}/devicecontrol`);

const uploaderEndpoint = (process.env.NODE_ENV === 'development' ?
      process.env.REACT_APP_DEV_UPLOADER_ENDPOINT :
      process.env.REACT_APP_UPLOADER_ENDPOINT 
    ); 
const query = queryString.parse(window.location.search);

if (query.transparent) {
  document.querySelector('html').classList.add('transparentBg');
}

if (query.emulatedevice) {
  ReactDOM.render(<div>
      <DeviceEmulator deviceLinkSocket={deviceLinkSocket} />
    </div>, document.getElementById('root'));
}
else {
  ReactDOM.render(<div className={(query.hideheader ? 'hideHeader ' : '')}>
      {!query.mobile && <AppDashboard
        screen={query.screen}
        deviceLinkSocket={deviceLinkSocket}
        deviceCode={query.devicecode}
        uploaderEndpoint={uploaderEndpoint}
        hideScreens={(query.hidescreens||"").split(",")} /> }
      {!!query.mobile && <AppMobileUpload
        uploaderEndpoint={uploaderEndpoint}
        framed={true} deviceLinkSocket={deviceLinkSocket} /> }
    </div>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();