import React from 'react';
import './App.css';
import './AppDashboard.css';
import AppMobileUpload from './AppMobileUpload';
import LicenseManager from './LicenseManager';
import XRRecordingManager from './XRRecordingManager';
import DatasetManager from './DatasetManager';
import PresetManager from './PresetManager';
import { timingSafeEqual } from 'crypto';
import DeviceLinkDialog from './DeviceLinkDialog';
import queryString from 'query-string';
import qrcode from 'qrcode';

// 1. Linking
// A) link via code
// Join code room, request device id & token
// store device id & token, then B)
// B) link via stored token
// join device id room, verify token,
// then join token room (-> Device joins token room as well)
//
// 2. In token Room
// communicate with device, e.g.:
// - request license status
// - try to activate licenses

class AppDashboard extends React.Component {

  state = {
    activeDevice: "", // "deviceId", 
    devices: {
      /*
      e.g.
      "deviceid": {
        type: "vr", // ar, quest, desktop...
        label: "oculus",
        id: "3232-23232-3232-abc-abc",
        token: token,
        status: "unknown" | "token_invalid" | "linked" | "linking",
        online: false,
      }
      */
    },
    datasets: {}, // map by devices ids
    enterDeviceCode: false,
    deviceLinkVerifying: false,
    p2pDataChannel: null,
    p2pConnection: null,
    p2pConnectionsInfo: {
      /*
      e.g.
      "deviceid": {
        p2pFirstConnectionGood: true - first connection was successful,
        forceP2PConnection: true - force p2p reconnect
        supportP2P: true - device supports p2p connection
      }
      "deviceid": {
        p2pFirstConnectionGood: false - first connection was unsuccessful,
        forceP2PConnection: false
        supportP2P: false - device does not support p2p connection
      }
      */
    }, 
    currentPanel: 'upload',
    linkConnectionState: null,
  }
  linkConnectionStates =
  {
    p2pConnected: 'p2p-connected',
    p2pConnecting: 'p2p-connecting',
    serverConnected: 'server-connected',
    p2pConnecionFailed: 'p2p-failed'
  }
  p2pConnectionAttemptsCtr = 0;
  p2pConnectionAttemptsLimit = 5;

  updateDevice({label, type, id, online}) {
    // Selected device goes online - connect p2p
    let deviceP2PConnectionInfo = this.getDeviceP2PConnectionInfo(id);
    if (this.state.activeDevice === id && 
      online && 
      deviceP2PConnectionInfo !== null && 
      deviceP2PConnectionInfo.forceP2PConnection)
    {
      this.setState({p2pConnection: null, p2pDataChannel: null});
      this.linkDeviceP2P(this.state.devices[this.state.activeDevice])
    } else if (this.state.activeDevice === id && online && deviceP2PConnectionInfo === null) {
      this.sendSupportP2PMessage(this.state.devices[this.state.activeDevice])
    }

    // Selected device goes offline
    if (this.state.activeDevice === id && !online){
      this.setState({p2pConnection: null, p2pDataChannel: null});
    }
    
    const device = Object.assign({
      status: "unknown"
    }, this.state.devices[id] || {}, {
      type, id, status: "linked", online, label
    });
    this.state.devices[id] = device;
    this.setState({devices: this.state.devices});
  }

  removeDevice(id) {
    delete this.state.devices[id];
    this.setState({devices: this.state.devices});
    this.persistDevices();
  }

  updateDeviceToken(device, token) {
    this.updateDevice(device);
    this.state.devices[device.id].token = token;
    this.setState({devices: this.state.devices});

    this.persistDevices();
  }

  updateDeviceP2PConnectionInfo(deviceId, p2pFirstConnectionGood, forceP2PConnection, supportP2P) {
    let deviceP2PConnectionInfo = Object.assign(
      this.state.p2pConnectionsInfo[deviceId] || {},
    );

    if (p2pFirstConnectionGood !== undefined)
      deviceP2PConnectionInfo.p2pFirstConnectionGood = p2pFirstConnectionGood;

    if (forceP2PConnection !== undefined)
      deviceP2PConnectionInfo.forceP2PConnection = forceP2PConnection;

    if (supportP2P !== undefined)
      deviceP2PConnectionInfo.supportP2P = supportP2P;  

    this.state.p2pConnectionsInfo[deviceId] = deviceP2PConnectionInfo;
    this.setState({p2pConnectionsInfo: this.state.p2pConnectionsInfo});
  }

  getDeviceP2PConnectionInfo(deviceId) {
    if (this.state.p2pConnectionsInfo.hasOwnProperty(deviceId))
      return this.state.p2pConnectionsInfo[deviceId];
    return null;
  }

  persistDevices() {
    localStorage.setItem('devices', JSON.stringify(this.state.devices));
  }

  componentWillMount() {
    let deviceLinkSocket = this.props.deviceLinkSocket;

    deviceLinkSocket.on('apperror', (msg) => {
      const {error, message} = msg;
      switch (error) {
        case 'device_token_invalid':
          const {token} = msg;
          // find device with token
          Object.keys(this.state.devices).forEach(id => {
            const device = this.state.devices[id];
            if (device.token && device.token === token) {
              delete device.token;
              device.status = "token_invalid";
            }
          });
          break;
        case 'device_code_invalid':
          this.setState({
            deviceLinkVerifying: false,
            enterDeviceCode: true
          });
          break;
        default:
          console.log('apperror', error, message);
      }
    });

    deviceLinkSocket.on('token', ({token, device}) => {
      // new token, send link request
      this.updateDeviceToken(device, token);

      this.setState({
        deviceLinkVerifying: false,
        enterDeviceCode: false
      });
      deviceLinkSocket.emit('link_request', {token});
    });

    deviceLinkSocket.on('device', (device) => {
      // we have connection
      const newlyLinked = !(device.id in this.state.devices) || this.state.devices.status !== 'linked';

      this.updateDevice(device);
      device = this.state.devices[device.id];

      if (device.online && !this.state.activeDevice) {
        this.selectDevice(device.id);
      }
    });

    deviceLinkSocket.on('link_message', this.handleP2PLinkMessage);

    const storedDevices = localStorage.getItem('devices');
    let devices = {};
    if (storedDevices) {
      devices = JSON.parse(storedDevices);

      Object.keys(devices).forEach(id => {
        const device = devices[id];
        device.online = false;
        device.status = "linking";

        if (device.token) {
          deviceLinkSocket.emit('link_request', {token: device.token});
        }
        else {
          device.status = 'token_invalid';
        }
      });

      this.setState({devices});
    }

    const deviceCount = Object.keys(devices).length;
    switch (deviceCount) {
      case 0:
        this.showDeviceCodeEntry();
        break;
      default:
       break;
    }
  }
  
  componentDidMount() {
    if (this.props.deviceCode) {
      this.linkDevice(this.props.deviceCode);
    }
    if (this.props.screen) {
      this.setState({currentPanel: this.props.screen})
    }
  }

  linkDevice(code) {
    this.setState({
      deviceLinkVerifying: true
    });

    this.props.deviceLinkSocket.emit('get_token', {code});
  }

  unlinkDevice() {
    this.setState({deviceLinkVerifying: false, linkedMhdClient: null, cloudLinkCode: null});
    localStorage.removeItem('cloudlink');
    this.props.deviceLinkSocket.emit('leave room');
  }

  async linkDeviceP2P(device) {
    const config = {
      iceServers: [
        { 
          urls: "stun:ms-comm.ca7cthywqan.nooon.io:443" 
        },
        {
          urls: 'turn:ms-comm.ca7cthywqan.nooon.io:443',
          credential: process.env.REACT_APP_TURN_SERVER_PASSWORD,
          username: process.env.REACT_APP_TURN_SERVER_USERNAME
        }
      ],
    };

    const pc = new RTCPeerConnection(config);
    const dataChannel = pc.createDataChannel('dataChannel')

    this.setState({
      p2pDataChannel: dataChannel,
      p2pConnection: pc,
    });

    dataChannel.binaryType = "arraybuffer";
    dataChannel.onopen = () => console.log("Data channel open");

    dataChannel.onerror = e => {
      this.handleP2PConnectingFailure("Error", device)
    };

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    pc.onconnectionstatechange = e => {
      console.log("P2P connection state changed: " + pc.connectionState);
      this.handleP2PConnectionStatusChange(pc.connectionState, device)
    };
    pc.onsignalingstatechange = e => console.log("P2P signaling state changed: " + pc.signalingState);
    pc.onicecandidateerror = e => console.log("P2P candidate error: " + e.errorText);
    pc.onnegotiationneeded = async e => {
      console.log("Negotiation needed")
      // let currentDevice = this.state.devices[this.state.activeDevice];
      try {
        await pc.setLocalDescription()
        this.sendPeerOffer(device, pc.localDescription);
      } catch (err) {
        console.error(err);
      }
    };
    pc.onicecandidate = (e) => {
      if(!e.candidate)
        return;
      this.props.deviceLinkSocket.emit('link_message', {
        token: device.token,
        msg: {
          type: 'peer_connection',
          peer_msg: {
            MessageType: 3,
            Data: JSON.stringify(e.candidate),
            IceDataSeparator: "|"
          }
        }
      });
    }

    let timeoutMs = 3 * 1000;
    setTimeout(() => this.handleP2PTimeout(device), (timeoutMs))

    this.sendPeerOffer(device, offer)
  }

  sendPeerOffer(device, offer){
    this.props.deviceLinkSocket.emit('link_message', {
      token: device.token,
      msg: {
        type: 'peer_setup',
        deviceId: device.id,
        peer_msg: {
          MessageType: 1,
          Data: offer.sdp,
          IceDataSeparator: ""
        }
      }
    });
  }

  sendSupportP2PMessage(device){
    this.props.deviceLinkSocket.emit('link_message', {
      token: device.token,
      msg: {
        type: 'supportP2P',
        deviceId: device.id,
      }
    });
  }

  handleP2PTimeout(device){
    if(this.state.p2pConnection && this.state.p2pConnection.remoteDescription === null){
      this.handleP2PConnectingFailure(device);
      console.log("No response p2p connection failure");
    }
  }

  unlinkDeviceP2P(device) {
    if (!device || (device && !device.online))
      return;

    if(this.state.p2pDataChannel)
      this.state.p2pDataChannel.close();
    if(this.state.p2pConnection)
      this.state.p2pConnection.close();
    this.props.deviceLinkSocket.emit('link_message', {
      token: device.token,
      msg: {
        type: 'peer_connection-end',
        peer_msg: {
          end: true
        }
      }
    });
    this.setState({
      p2pDataChannel: null,
      p2pConnection: null,
    });
  }

  handleP2PConnectionStatusChange = (status, device) => {
    switch (status) {
      case "new":
        // no action needed for now
        break;
      case "connecting":
        this.setState({linkConnectionState: this.linkConnectionStates.p2pConnecting});
        break;
      case "connected":
        this.p2pConnectionAttemptsCtr = 0;
        this.updateDeviceP2PConnectionInfo(device.id, true);
        this.setState({linkConnectionState: this.linkConnectionStates.p2pConnected});
        break;
      case "disconnected":
        this.handleP2PConnectingFailure(device);
        break;
      case "closed":
        this.handleP2PConnectingFailure(device);
        break;
      case "failed":
        this.handleP2PConnectingFailure(device);
        break;
      default:
        console.error("Unknown status: " + status);
        break;
    }
  }

  // reconnect if first connection was good
  handleP2PConnectingFailure = (device) => {
    let deviceP2PConnectionInfo = this.getDeviceP2PConnectionInfo(device.id);
    
    if (deviceP2PConnectionInfo === null || deviceP2PConnectionInfo.p2pFirstConnectionGood === null)
      this.updateDeviceP2PConnectionInfo(device.id, false);

    if (deviceP2PConnectionInfo && device.id === this.state.activeDevice &&
      (deviceP2PConnectionInfo.p2pFirstConnectionGood !== false || deviceP2PConnectionInfo.forceP2PConnection)) 
    {
      if (this.p2pConnectionAttemptsCtr >= this.p2pConnectionAttemptsLimit)
      {
        // p2p failed too many times, cannot connect to cloud due to forceP2PConnection
        this.setState({linkConnectionState: this.linkConnectionStates.p2pConnecionFailed});
        return;
      }

      this.setState({linkConnectionState: this.linkConnectionStates.p2pConnecting});
      this.p2pConnectionAttemptsCtr++;

      if (this.state.p2pConnection){
        this.state.p2pConnection.restartIce();
      }

    } else {
      this.setState({p2pConnection: null, p2pDataChannel: null, linkConnectionState: this.linkConnectionStates.serverConnected});
    }
  }

  handleP2PLinkMessage = rawMessage => {

    if (!rawMessage.msg.type || rawMessage.msg.type !== "p2p")
    {
      return;
    } 

    let messageDataJson = null;
    try
    {
      messageDataJson = JSON.parse(rawMessage.msg.rawData)
    }
    catch (err)
    {
      console.error("Could not parse P2P message!");
      return;
    }

    let msgData = messageDataJson.Data
    let msgDataType = messageDataJson.MessageType
    let msgDeviceId, msgForceP2P;
    // msgDataType 
    // 1 - Offer
    // 2 - Answer
    // 3 - ICE Candidate
    switch (msgDataType) {
      // Link is always inpolite so it's never gonna respect an offer
      // case 1:
      //   let remoteOffer = new RTCSessionDescription({type: 'offer', sdp: msgData});
      //   pc.setRemoteDescription(remoteOffer);
      //   const localOffer = await pc.createOffer()
      //   await pc.setLocalDescription(localOffer)
      //   this.props.deviceLinkSocket.emit('link_message', {
      //     token: device.token,
      //     msg: {
      //       type: 'peer_connection',
      //       peer_msg: {
      //         MessageType: 1,
      //         Data: localOffer.sdp,
      //         IceDataSeparator: ""
      //       }
      //     }
      //   });
      // break;
      case 2:
        if (!this.state.p2pConnection)
        {
          break;
        }
        let remoteAnsw = new RTCSessionDescription({type: 'answer', sdp: msgData});
        this.state.p2pConnection.setRemoteDescription(remoteAnsw);
      break;
      case 3:
        if (!this.state.p2pConnection)
        {
          break;
        }
        let parts = msgData.split(messageDataJson.IceDataSeparator).filter((x) => {return  x != "" });
        let candidate = new RTCIceCandidate({candidate: parts[0], sdpMLineIndex: parseInt(parts[1]), sdpMid: parts[2]})
        this.state.p2pConnection.addIceCandidate(candidate)
      break;
      case "ConnectionStatus":
        if (!this.state.p2pConnection)
        {
          break;
        }
        msgDeviceId = messageDataJson.deviceId
        let currentDevice = this.state.devices[this.state.activeDevice];
        if (currentDevice.id !== msgDeviceId){
          if (!this.state.devices[msgDeviceId])
            return;
          this.unlinkDeviceP2P(this.state.devices[msgDeviceId]);
          return;
        }

        this.handleP2PConnectionStatusChange(msgData.toLowerCase(), currentDevice);
        console.log("Unity connection status: " + msgData);
      break;
      case "SetupP2PConnection":
        msgForceP2P = messageDataJson.forceP2P.toLowerCase();
        msgDeviceId = messageDataJson.deviceId
        if (msgForceP2P === "true"){
          this.updateDeviceP2PConnectionInfo(msgDeviceId, null, true);
        }
        else if (msgForceP2P === "false")
          this.updateDeviceP2PConnectionInfo(msgDeviceId, null, false);
      break;
      case "ForceP2PChanged":
        msgForceP2P = messageDataJson.forceP2P.toLowerCase();
        msgDeviceId = messageDataJson.deviceId
        if (msgForceP2P === "true")
          this.updateDeviceP2PConnectionInfo(msgDeviceId, undefined, true);
        else if (msgForceP2P === "false")
          this.updateDeviceP2PConnectionInfo(msgDeviceId, undefined, false);

        var force = msgForceP2P === "true";
        if (!force || this.state.activeDevice !== msgDeviceId)
        {
          break;
        }

        if (!this.state.p2pDataChannel || this.state.p2pDataChannel.readyState !== "open")
        {
          this.linkDeviceP2P(this.state.devices[msgDeviceId])
          break;
        }
      break;
      case "SupportP2P":
        msgForceP2P = messageDataJson.forceP2P.toLowerCase();
        msgDeviceId = messageDataJson.deviceId;
        if (msgForceP2P === "true"){
          this.updateDeviceP2PConnectionInfo(msgDeviceId, undefined, true, true);
          msgDeviceId === this.state.activeDevice && this.linkDeviceP2P(this.state.devices[msgDeviceId]);
        }
        else if (msgForceP2P === "false")
          this.updateDeviceP2PConnectionInfo(msgDeviceId, undefined, false, true);   
      break;
    }
  }

  selectDevice(id) {
    const device = this.state.devices[id];

    if (this.state.activeDevice === id)
    {
      // do not set the same device as active twice
      return;
    }

    this.p2pConnectionAttemptsCtr = 0;

    if (!device || device.status !== "linked") {
      return;
    }

    let lastSelectedDevice = this.state.devices[this.state.activeDevice];
    
    if (lastSelectedDevice) {
      this.unlinkDeviceP2P(lastSelectedDevice);
    }

    let deviceP2PConnectionInfo = this.getDeviceP2PConnectionInfo(id);
    if(deviceP2PConnectionInfo === null) {
      this.sendSupportP2PMessage(this.state.devices[id])
    }

    if ( deviceP2PConnectionInfo !== null && deviceP2PConnectionInfo.forceP2PConnection)
    {
      this.setState({p2pConnection: null, p2pDataChannel: null, linkConnectionState: this.linkConnectionStates.p2pConnecting});
      this.linkDeviceP2P(device);
    } 
    else {
      this.setState({p2pConnection: null, p2pDataChannel: null, linkConnectionState: this.linkConnectionStates.serverConnected});
    }

    this.setState({activeDevice: id});
  }

  suppress(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  showDeviceCodeEntry() {
    this.setState({enterDeviceCode: true});
  }
  
  hideDeviceCodeEntry() {
    this.setState({enterDeviceCode: false});
  }

  handleP2PConnectionDisplay(device)
  {
    if (!device.online || this.state.activeDevice !== device.id)
    {
      return null;
    }

    let resultString = ", ";
    switch(this.state.linkConnectionState){
      case this.linkConnectionStates.p2pConnected:
        resultString += "P2P Connected";
      break;
      case this.linkConnectionStates.p2pConnecting:

        resultString += "P2P Connecting";

        if (this.p2pConnectionAttemptsCtr == 0)
        {
          break;
        }

        resultString += ` - Retrying (${this.p2pConnectionAttemptsCtr}/${this.p2pConnectionAttemptsLimit})`

      break;
      case this.linkConnectionStates.serverConnected:
        resultString += "Server Connected";
      break;
      case this.linkConnectionStates.p2pConnecionFailed:
        resultString += "P2P Connection Failed";
      break;
      default:
        console.warn("Unknown link connection state: " + this.state.linkConnectionState);
        return null;
      break;
    }

    return resultString;
  }

  render() {
    const dropZoneActive = this.state.linkConnectionState != this.linkConnectionStates.p2pConnecting && this.state.linkConnectionState != this.linkConnectionStates.p2pConnecionFailed;
    const device = this.state.activeDevice && this.state.devices[this.state.activeDevice];
    const dataChannel = this.state.p2pDataChannel;
    const inFrame = window.location !== window.parent.location && !this.state.linkedSet;
    const devices = Object.keys(this.state.devices).map(id => {
      const device = this.state.devices[id];
      return <div onClick={() => {
        this.selectDevice(device.id);
      }} className={"device title-panel " + (this.state.activeDevice == id ? 'selected' : '') + ' ' + (device.online ? 'device-online' : 'device-offline')} key={id} title={id}>
        <div className="header">
          <div>
            <span className="label">{device.label}</span>
            <span className="type">{device.type}</span>
          </div>
          <div className="controls">
            <span className="remove" onClick={() => {
              if (window.confirm(`This removes the link to ${device.label}. Continue?`)) this.removeDevice(device.id);
            }}>Remove</span>
          </div>
        </div>
        {device.status === "linked" && <span className={"state " + ((device.online && (this.state.linkConnectionState == this.linkConnectionStates.p2pConnecionFailed)) ? 'state-online-failed' : (device.online ? 'state-online' : 'state-offline'))}>{device.online ? 'Online' : 'Offline'}{this.handleP2PConnectionDisplay(device)}</span>}
        {device.status === "unknown" && <span className={"state state-unknown"}>Waiting for device</span>}
        {device.status === "linking" && <span className={"state state-linking"}>Connecting to device</span>}
        {device.status === "token_invalid" && <span className={"state state-token-invalid"}>Device Code needed</span>}
        {device.status === "linked" && device.online && <div className='P2P_handler'>
        </div>}
      </div>
    });

    return (
      <div className={"App" + (inFrame ? ' inframe' : '') + (this.state.linkedSet ? ' linkedset' : '')} onDrag={this.suppress} onDragStart={this.suppress} onDragOver={this.suppress} onDragEnter={this.suppress} onDragLeave={this.suppress} onDragEnd={this.suppress}  onDrop={this.suppress}>
        <header className="App-header title-panel">
          <img style={{display:'block', maxHeight: '3em', marginRight:'0.6em', height:'6vh'}} src="mhd-model.svg"/>
          <h1>Device Dashboard</h1>
        </header>
        <div className="devices content-panel">
          {devices}
          <div className="device device title-panel adddevice" onClick={() => this.setState({enterDeviceCode: true})}>
            <h3>+ Link device</h3>
          </div>
        </div>
        {device && <div className={"device-dashboard"}>
          <nav className={"panelnav"}>
            <ul>
              {this.props.hideScreens.indexOf("licenses") === -1 && <li><div className={this.state.currentPanel === 'licenses' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'licenses'})}>License Management</div></li>}
              {this.props.hideScreens.indexOf("datasets") === -1 && <li><div className={this.state.currentPanel === 'datasets' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'datasets'})}>Datasets</div></li>}
              {this.props.hideScreens.indexOf("xrrecordings") === -1 && <li><div className={this.state.currentPanel === 'xrrecordings' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'xrrecordings'})}>RecordXR</div></li>}
              {this.props.hideScreens.indexOf("presets") === -1 && <li><div className={this.state.currentPanel === 'presets' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'presets'})}>Presets</div></li>}
              {this.props.hideScreens.indexOf("upload") === -1 && <li><div className={this.state.currentPanel === 'upload' ? "title-panel" : "content-panel"} onClick={() => this.setState({currentPanel: 'upload'})}>Upload</div></li>}
            </ul>
          </nav>
          {this.state.currentPanel === 'licenses' && <div className="content-panel">
            <h2>Licenses</h2>
            <LicenseManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'datasets' && <div className="content-panel">
            <h2>Datasets</h2>
            <DatasetManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'xrrecordings' && <div className="content-panel">
            <h2>RecordXR</h2>
            <XRRecordingManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'presets' && <div className="content-panel">
            <h2>Presets</h2>
            <PresetManager
              device={device}
              deviceLinkSocket={this.props.deviceLinkSocket} />
          </div>}
          {this.state.currentPanel === 'upload' && <div className={"upload-panel content-panel" + (!dropZoneActive ? ' dragndrop-disabled' : '')}>
            <AppMobileUpload
              dropZoneActive={dropZoneActive}
              framed={true}
              device={device}
              uploaderEndpoint={this.props.uploaderEndpoint}
              p2pDataChannel={dataChannel}
              linkConnectionState={this.state.linkConnectionState}
              deviceLinkSocket={this.props.deviceLinkSocket}
              p2pConnectionInfo={this.state.p2pConnectionsInfo[this.state.activeDevice]} />
          </div>}
        </div>}
        {<div className={"overlay" + (this.state.enterDeviceCode ? ' shown' : '')}>
          <div className="inner" onClick={(e) => e.stopPropagation()}>
            <DeviceLinkDialog
              currentCode={this.state.cloudLinkCode}
              onEnter={(code) => this.linkDevice(code)}
              onClose={() => this.hideDeviceCodeEntry()}
              unlink={() => this.unlinkDevice()}
              linkedMhdClient={this.state.linkedMhdClient}
              deviceLinkVerifying={this.state.deviceLinkVerifying}
            />
          </div>
          <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.hideDeviceCodeEntry(); }} className="screentopright" style={{transform: 'rotateZ(45deg)', lineHeight: '1em', userSelect:'none', margin: '-0.5em 0 0 -0.2em', position:'fixed', pointer: 'default'}}>
            <span style={{ fontSize: '60px'}}>+</span>
          </div>
        </div>}
      </div>
    );
  }
}

export default AppDashboard;
