import React from 'react';

class LicenseManager extends React.Component {
  state = {
    licenses: []
  }

  componentWillMount() {
    this.processLinkMessage = this.processLinkMessage.bind(this);

    this.props.deviceLinkSocket.on('link_message', this.processLinkMessage);
    this.updateLicenses();
  }

  componentDidUpdate(pProps) {
    if ((this.props.device.online && !pProps.device.online) || (this.props.device && (!pProps.device || pProps.device.id !== this.props.device.id))) {
      this.updateLicenses();
    }
  }

  updateLicenses() {
    // update licenses
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'get_license_status'
      }
    });
  }

  componentWillUnmount() {
    this.props.deviceLinkSocket.off('link_message', this.processLinkMessage);
  }

  processLinkMessage({from, msg}) {
    if (from === this.props.device.id && "type" in msg && msg.type === 'licenses') {
      this.setState({licenses: msg.licenses});
    }
  }

  activateLicense(license, key) {
    this.props.deviceLinkSocket.emit('link_message', {
      token: this.props.device.token,
      msg: {
        type: 'activate_product',
        product: license.productName,
        key: key
      }
    });
  }

  render() {
    const licenses = (this.state.licenses || []).map(license => {
      return <div className={"title-panel"} key={license.productName}>
        <h3>{license.displayName}</h3>
        <p>Status: {license.active ? "active" : "inactive"}</p>
        {license.active && <p>Expires: {license.expiryDate}</p>}
        {!license.active && <button onClick={() => this.activateLicense(license, prompt("Please enter a valid license key:"))} type="submit">Activate</button>}
      </div>
    });

    return (
      <div className="licenses" style={{display:'flex', textAlign:'left', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'left'}}>
        {
          !this.props.device.online &&
            'Your device is offline. Please run Medicalholodeck on your device.'
        }
        { this.props.device.online &&
            licenses }
      </div>
    );
  }
}

export default LicenseManager;
